var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-sm-row align-sm-center"},[_c('div',{staticClass:"d-flex flex-row align-center my-2 my-sm-0"},[_c('v-dialog',{attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(_vm.relative.date)}},'div',attrs,false),on))]}}]),model:{value:(_vm.dialogs.date),callback:function ($$v) {_vm.$set(_vm.dialogs, "date", $$v)},expression:"dialogs.date"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialogs.date = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.travel}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mx-2 font-weight-bold",domProps:{"textContent":_vm._s(_vm.relative.time)}},'div',attrs,false),on))]}}]),model:{value:(_vm.dialogs.time),callback:function ($$v) {_vm.$set(_vm.dialogs, "time", $$v)},expression:"dialogs.time"}},[(_vm.dialogs.time)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":"","scrollable":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialogs.time = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.travel}},[_vm._v(" OK ")])],1):_vm._e()],1)],1),_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-btn-toggle',{staticClass:"mx-sm-2",attrs:{"mandatory":"","borderless":"","dense":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-btn',{attrs:{"x-small":"","value":0}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pause ")])],1),_c('v-btn',{attrs:{"x-small":"","value":1}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-play ")])],1),_c('v-btn',{attrs:{"x-small":"","value":2},on:{"click":function($event){_vm.speed *= 2}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-fast-forward ")])],1)],1),(_vm.speed > 1)?_c('span',{staticClass:"mx-2"},[_vm._v(" "+_vm._s(_vm.speed)+"x ")]):_vm._e(),(_vm.showReset)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":""},on:{"click":_vm.reset}},'v-icon',attrs,false),on),[_vm._v(" mdi-backup-restore ")])]}}],null,false,3554852090)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("timetravel.back"))+" ")])]):_vm._e(),(_vm.daysFromEpoch > 14)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"yellow","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,false,1505199744)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("timetravel.outdated_tle", { daysFromEpoch: _vm.daysFromEpoch }))+" ")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":""},on:{"click":_vm.shareURL}},'v-icon',attrs,false),on),[_vm._v(" mdi-share ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.shareText)+" ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }