var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{ref:"satellites",attrs:{"loading":_vm.loading,"items":_vm.satellites,"search-input":_vm.query,"filter":_vm.filter,"hide-details":"","hide-no-data":"","solo-inverted":"","persistent-hint":"","dense":"","flat":"","label":"Satellite..."},on:{"update:searchInput":function($event){_vm.query=$event},"update:search-input":function($event){_vm.query=$event},"input":function($event){!_vm.query && _vm.$refs.satellites.blur()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.text)+" "),_c('small',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.getCatalogNumber(item.value)))])]}}]),model:{value:(_vm.tle),callback:function ($$v) {_vm.tle=$$v},expression:"tle"}}),_c('div',{staticClass:"d-flex flex-column my-4"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.setFollow(!_vm.config.follow)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.config.follow ? 'primary': ''}},[_vm._v(" mdi-image-filter-center-focus ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.follow_satellite')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1",attrs:{"fab":"","x-small":""},on:{"click":_vm.geolocate}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.observer.enabled && !_vm.observer.fromIp ? 'primary' : ''}},[_vm._v(" mdi-crosshairs-gps ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.locate_me')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1",attrs:{"fab":"","x-small":"","disabled":!_vm.observer.enabled},on:{"click":_vm.toggleNotifications}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.config.notifications ? 'primary': ''}},[_vm._v(" mdi-bell ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.notifications')))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1",attrs:{"x-small":"","fab":""},on:{"click":function($event){return _vm.$emit('showInfo')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-information-variant ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.info')))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }